<template>
  <div class="wrapper login-wrapper">
    <div class="container flex-col px-1">
      <div
        class="w-6/12 mobile:w-full medium:w-9/12 flex flex-col mx-auto mt-12 items-center login-container px-12 mobile:p-5 mobile:mb-16 mobile:mt-0"
      >
        <img src="/logotext.png" class="w-1/2 mb-8" />
        <div class="error-box w-full mb-5" v-if="error">
          <div
            class="w-2/12 text-4xl flex items-center justify-start leading-none"
          >
            <span class="typcn typcn-times"></span>
          </div>
          <div class="w-10/12 flex items-center">
            {{ errorText }}
          </div>
        </div>
        <input
          id="emailField"
          data-cy="email"
          type="text"
          v-model="user.email"
          class="w-full mb-3 shadow-sm focus:shadow-outline bg-offwhite rounded-xl"
          placeholder="Meiliaadress"
          @keyup.enter="goToPassword"
        />
        <input
          id="passwordField"
          data-cy="password"
          type="password"
          v-model="user.password"
          placeholder="Parool"
          class="w-full shadow-sm focus:shadow-outline bg-offwhite rounded-xl"
          @keyup.enter="login()"
        />
        <button
          class="mt-3 w-full new-button-green text-lg tracking-wide"
          @click="login()"
          data-cy="loginButton"
        >
          <span class="label py-1">Logi sisse</span>
        </button>
        <div class="flex flex-row w-full rounded-md mt-5 p-5 mobile:flex-col">
          <div
            class="flex flex-col w-1/2 justify-center items-center mobile:mb-3 px-3 mobile:w-full mobile:px-0"
          >
            <h4 class="mb-2">Pole kontot?</h4>
            <router-link to="/register">
              <button class="new-button-green">
                <span class="label text-lg py-1">Liitu</span>
              </button>
            </router-link>
          </div>
          <div
            class="flex flex-col w-1/2 justify-center items-center px-3 mobile:w-full mobile:px-0"
          >
            <h4 class="mb-2">Unustasid parooli?</h4>
            <button
              class="new-button-green py-2"
              @click="$router.push('/reset-password')"
            >
              <span class="label text-lg py-1">Taasta konto</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {
        email: "",
        password: ""
      },
      error: false,
      errorText:
        "Midagi läks valesti, kontrollige oma kasutajadetaile ja proovige uuesti."
    };
  },
  methods: {
    login() {
      let user = this.user;
      user.email = user.email.toLowerCase();
      if (user.email && user.password) {
        this.$store.state.loading = true;
        this.$store
          .dispatch("auth/login", user)
          .then(async res => {
            if (res.status === 200) {
              if (this.target) {
                this.$router.push("/" + this.target);
              } else {
                this.$router.push("/calendar");
              }
              this.$store.state.loading = false;
            } else {
              this.error = true;
              this.errorText =
                "Sisselogimine ebaõnnestus, palun kontrollige meili ja parooli!";
              this.$store.state.loading = false;
            }
          })
          .catch(() => {
            this.error = true;
            this.errorText =
              "Sisselogimine ebaõnnestus, palun kontrollige meili ja parooli!";
            this.$store.state.loading = false;
          });
      } else {
        this.errorText = "Palun täitke mõlemad väljad!";
        this.error = true;
      }
    },
    goToPassword() {
      document.getElementById("passwordField").select();
    }
  },
  computed: {
    target() {
      if (this.$route.query.target) {
        return this.$route.query.target;
      } else {
        return false;
      }
    }
  }
};
</script>

<style lang="scss">
.login-wrapper {
  background-image: url("/loginbg.png");
  background-repeat: repeat;
  background-size: cover;
  background-position: center;
}
.login-container {
  @apply bg-white bg-opacity-90 shadow-lg py-5 rounded-xl;
}
</style>
